import {Component, TemplateRef, ViewChild} from "@angular/core";
import {
  CtBinaryOperator,
  CtDatatableActionCustomConfiguration,
  CtDatatableConfiguration,
  CtModelConfiguration,
  CTModelDatatableFilter,
  CtModelOperator
} from "@ctsolution/ct-framework";
import {HandlingService} from "../handling.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DEFAULT_DATATABLE_CONFIGURATION} from "../../../_core/constants";
import {Handling} from "../../../_core/classes/handling";
import {HandlingOriginEnum} from "../../../_core/enum/handling-origin.enum";
import {HandlingRouteData} from "../../../_core/route-data/handling.route-data";
import {LangDownloadFileConfiguration} from "../../lang-download-file/lang-download-file.configuration";
import {ReportDownloadParameter} from "../../../_core/classes/report-download.parameter";
import {HandlingStateEnum} from "../../../_core/enum/handling-state.enum";

@Component({
  selector: "app-handling-create-list",
  template: `

    <ct-model *ngIf="configuration" [configuration]="configuration"></ct-model>

    <ng-template #downloadPdfButton let-value="value">

      <app-lang-download-file [configuration]="langDownloadFileConfiguration"
                              (onDownload)="downloadPdf(value, $event)"></app-lang-download-file>
    </ng-template>

    <ng-template #deliveryDateCell let-value="value" let-row="row">

      <p style="display: flex;align-items: center;">

        <mat-icon style="font-size: 21px" *ngIf="value && expiredHandlingDelivery(row)" color="warn">
          notification_important
        </mat-icon>

        {{ value | date: 'dd/MM/yy' }}

      </p>

    </ng-template>

    <ng-template #handlingStateCell let-value="value">

      <p style="display: flex;align-items: center;">

        <mat-icon style="font-size: 21px; color: green;" *ngIf="deliveredHandling(value)">
          task_alt
        </mat-icon>
        {{ ('handlingStates.' + value) | translate }}

      </p>

    </ng-template>

  `
})
export class HandlingListComponent {

  @ViewChild("downloadPdfButton") downloadPdfButton: TemplateRef<any> | null = null;
  @ViewChild("deliveryDateCell") deliveryDateCell: TemplateRef<any> | null = null;
  @ViewChild("handlingStateCell") handlingStateCell: TemplateRef<any> | null = null;

  configuration: CtModelConfiguration<HandlingListComponent> | null = null;

  langDownloadFileConfiguration: LangDownloadFileConfiguration = LangDownloadFileConfiguration
    .create()
    .setColor("primary")
    .setIcon("picture_as_pdf")
    .setButtonType('mat-icon-button');

  datatableConfiguration: CtDatatableConfiguration = DEFAULT_DATATABLE_CONFIGURATION();

  constructor(
    private handlingService: HandlingService,
    private route: ActivatedRoute,
    private router: Router) {
  }

  expiredHandlingDelivery = (value: Handling): boolean => !this.deliveredHandling(value.HandlingState) && new Date().getTime() > new Date(value.DeliveryDate ?? -1).getTime();

  deliveredHandling = (value: HandlingStateEnum | null): boolean => value === HandlingStateEnum.Delivered;

  ngAfterViewInit() {

    this.datatableConfiguration
      .setCustomDatatableActionConfiguration(
        CtDatatableActionCustomConfiguration
          .create()
          .setTemplateOutlet(this.downloadPdfButton));

    this.constantOrigin()
      .then(origin => {

        const configuration = CtModelConfiguration
          .create<HandlingListComponent>()
          .setOperations([CtModelOperator.Create, CtModelOperator.Update])
          .setRouteData(HandlingRouteData())
          .setOnEdit((event: Handling) => this.router.navigate(["..", "edit", event.Oid!.toString()], {relativeTo: this.route}))
          .setFieldsCustomTemplates([
            {
              prop: (origin['HandlingOrigin'] === HandlingOriginEnum.Supplier ? "LocationFrom" : "SupplierFrom"),
              visible: false
            },
            {
              prop: 'DeliveryDate',
              cellTemplate: this.deliveryDateCell
            },
            {
              prop: 'HandlingState',
              cellTemplate: this.handlingStateCell
            }
          ])
          .setCTDatatableConfiguration(this.datatableConfiguration)
          .setCustomLabel('createButtonLabel', origin['HandlingOrigin'] === HandlingOriginEnum.Supplier ? 'createOrder' : 'createHandling');

        configuration
          .filterValues
          .addConstantFilter(
            CTModelDatatableFilter
              .create()
              .setField('HandlingOrigin')
              .setValue(origin['HandlingOrigin'])
              .setOperatorType(CtBinaryOperator.Equal)
          )

        this.configuration = configuration;

      });

  }

  downloadPdf(value?: { Oid: number | null }, lang = 'it') {

    if (!value?.Oid) return;

    const parameter: ReportDownloadParameter = ReportDownloadParameter
      .create()
      .setHandlingOid(value.Oid)
      .setTemplateLanguage(lang)
      .setDirectDownload(false)
      .setOpenInBrowser(true);

    this.handlingService.downloadHandlingPDF(parameter);

  }

  constantOrigin(): Promise<{ [p: string]: any }> {

    return new Promise<{ [p: string]: any }>((resolve) => {

      const originPathValue: string | null = this.route.snapshot.paramMap.get("origin");
      const HandlingOrigin: HandlingOriginEnum = this.handlingService.getHandlingTypeByRouteValue(originPathValue);

      this.route
        .data
        .subscribe(data => {

          switch (HandlingOrigin) {

            case HandlingOriginEnum.Supplier:
              data['title'] = "CT_MENU.order";
              break;
            case HandlingOriginEnum.Location:
            default:
              data['title'] = "CT_MENU.handling";
              break;
          }

          resolve({HandlingOrigin});

        });

    });

  }

  protected readonly HandlingStateEnum = HandlingStateEnum;
}
