import {DataRequest} from "@ctsolution/ct-webapi";
import {Injectable} from "@angular/core";
import {map} from "rxjs/operators";
import {environment} from "../../../environments/environment";
import {FileService} from "../lib/file.service";
import {ReportDownloadParameter} from "../classes/report-download.parameter";

const CONTROLLER: string[] = ["report"];
const CONTROLLER_REQUEST = (): DataRequest => new DataRequest().setController(CONTROLLER);

@Injectable({
  providedIn: 'root'
})
export class ReportController {

  constructor(private fileService: FileService) {
  }

  getLocationQR(locationOid: number) {

    const request: DataRequest = CONTROLLER_REQUEST()
      .setAction("LocationQR")
      .setQueryParams({locationOid, hideSpinner: true});

    return this.fileService
      .getFile(request);

  }

  getLocationPDF(parameter: ReportDownloadParameter) {

    const request: DataRequest = CONTROLLER_REQUEST()
      .setAction("LocationPdf")
      .setQueryParams({...parameter, hideSpinner: true});

    return this.fileService
      .getFile(request)
      .pipe(
        map(result => {

          if (parameter.directDownload) {

            this.fileService.blobDownload(result, `${environment.projectName}_deposit${parameter.locationOid}`);

          }

        })
      );

  }

  getHandlingPDF(parameter: ReportDownloadParameter,) {

    const request: DataRequest = CONTROLLER_REQUEST()
      .setAction("HandlingPdf")
      .setQueryParams({...parameter, hideSpinner: true});

    return this.fileService
      .getFile(request)
      .pipe(
        map(result => {

          if (parameter.directDownload) {

            this.fileService.blobDownload(result, `${environment.projectName}_handling${parameter.handlingOid}`);

          }

          if (parameter.openInBrowser) {

            window.open(URL.createObjectURL(result));

          }

        })
      );

  }

}
