<div class="module-item-order-container">

  <mat-list>

    <div *ngFor="let handling of handlings; let last = last;">

      <div mat-subheader> {{ 'LocationFrom' | translate }} {{ getSourceFrom(handling) ?? 'unknown' }}</div>

      <mat-list-item *ngFor="let itemHandling of handling.ItemHandlings">

        <mat-icon matListItemIcon>{{ getSourceIcon(handling) }}</mat-icon>
        <div mat-line><strong> {{ itemHandling.Item?.Code }}</strong> {{ itemHandling.Item?.Name }}</div>
        <div mat-line>{{ itemHandling.Qty }} {{ itemHandling.UM?.Symbol }}</div>

      </mat-list-item>

      <mat-divider *ngIf="!last"></mat-divider>

    </div>

  </mat-list>

</div>
