import {Injectable} from "@angular/core";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {ItemSelectionComponent} from "./item-selection/item-selection.component";
import {ItemSelectionConfiguration} from "./item-selection/item-selection.configuration";
import {SelectedItemsConfiguration} from "../selected-items/selected-items.configuration";
import {CTManagerItemStock} from "../../../../../_core/classes/ct-manager-item-stock";
import {ItemHandling} from "../../../../../_core/classes/item-handling";

@Injectable()
export class ItemSelectionService {

  constructor(private dialog: MatDialog) {
  }

  open = (data: ItemSelectionConfiguration | null = null) => this.dialog.open(ItemSelectionComponent, {
    disableClose: true,
    data,
    minWidth: "65vw"
  });

  updateSelection(event: CTManagerItemStock[] = [], selectedItemsConfiguration: SelectedItemsConfiguration) {

    selectedItemsConfiguration
      .selectItems(event);

  }

}
