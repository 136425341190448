import {Component} from '@angular/core';
import {
  SelectedItemsConfiguration
} from "../../../../../../handling/order-handling-wizard/order-handling-wizard-step2/selected-items/selected-items.configuration";
import {
  ItemSelectionConfiguration
} from "../../../../../../handling/order-handling-wizard/order-handling-wizard-step2/item-selection/item-selection/item-selection.configuration";
import {
  ItemSelectionService
} from "../../../../../../handling/order-handling-wizard/order-handling-wizard-step2/item-selection/item-selection.service";
import {
  ItemSelectionImportService
} from "../../../../../../handling/order-handling-wizard/order-handling-wizard-step2/item-selection/item-selection-import/item-selection-import.service";
import {MatLegacyDialogRef} from "@angular/material/legacy-dialog";
import {HandlingOriginEnum} from "../../../../../../../_core/enum/handling-origin.enum";
import {CTManagerItemStock} from "../../../../../../../_core/classes/ct-manager-item-stock";
import {CTManagerItem} from "../../../../../../../_core/classes/ct-manager-item";
import {SelectionType} from "@swimlane/ngx-datatable";

@Component({
  selector: 'app-movement-manager-search-item',
  templateUrl: './movement-manager-search-item.component.html',
  providers: [ItemSelectionService, ItemSelectionImportService]
})
export class MovementManagerSearchItemComponent {

  itemSelectionConfiguration: ItemSelectionConfiguration = ItemSelectionConfiguration
    .create()
    .setSelectedItemsConfiguration(
      SelectedItemsConfiguration
        .create()
        .setTableMode('itemhandling'))
    .setImportEnabled(false)
    .setOrigin(HandlingOriginEnum.Supplier)
    .setSelectionType(SelectionType.single);

  constructor(private dialogRef: MatLegacyDialogRef<MovementManagerSearchItemComponent>) {
  }

  onSelect(event: CTManagerItemStock[] = []) {

    if (event.length) {

      const itemStock = event[0];

      this.dialogRef
        .close(CTManagerItem.createFromCTManagerItemStock(itemStock))

    }

  }

  close() {

    this.dialogRef
      .close();

  }

}
