<div class="movement-manager-container">

  <section class="m-b-10">

    <app-movement-manager-insert-mode (onInsert)="insert($event)"></app-movement-manager-insert-mode>

  </section>

  <section>

    <app-movement-manager-rows #movementManagerRowsComponent></app-movement-manager-rows>

  </section>

</div>
