<mat-card>

  <mat-card-content>

    <ct-form *ngIf="formConfiguration" [configuration]="formConfiguration"></ct-form>

    <mat-card-actions
      fxLayout="row wrap"
      fxLayoutAlign="space-between center">

      <div>

        <ct-button [configuration]="cancelButton"></ct-button>

      </div>

      <div>

        <input type="file" accept=".xlsx, .xls, .csv" #fileImport
               (change)="import($event)" onclick="this.value=null;" hidden/>

        <button
          *ngIf="configuration?.modelOid && configuration?.importEnabled"
          (click)="fileImport.click()" mat-raised-button color="primary">
          <mat-icon>publish</mat-icon>
          Import
        </button>

        <ct-button [configuration]="submitButton"></ct-button>

      </div>

    </mat-card-actions>

  </mat-card-content>

</mat-card>
