import {Injectable} from "@angular/core";
import {CtWebapiService, DataRequest, MethodEnum} from "@ctsolution/ct-webapi";
import {Handling} from "../classes/handling";
import {ItemRouteData} from "../route-data/item.route-data";

const CONTROLLER_REQUEST = (): DataRequest => new DataRequest().setController([ItemRouteData().controller!]);

@Injectable()
export class ItemController {

  constructor(private _webapi: CtWebapiService) {
  }

  moduleCreationRequest(parameter: ModuleCreationRequestParameter) {

    const request: DataRequest = CONTROLLER_REQUEST()
      .setAction("ModuleCreationRequest")
      .setMethod(MethodEnum.POST)
      .setQueryParams({
        hideSpinner: true
      })
      .setBody(parameter);

    return this._webapi
      .request(request);

  }

}

export class ModuleCreationRequestResult {

  RequestFullFilled: boolean | null = null;
  Errors: any[] | null = null;
  ModuleQty: number | null = null;
  Handlings: Handling[] | null = null;

}

export class ModuleCreationRequestParameter {

  ItemModuleOid: number | null = null;
  Qty: number | null = null;
  LocationToOid: number | null = null;
  Finalize: boolean | null = null;

  private constructor() {
  }

  public static create = (): ModuleCreationRequestParameter => new ModuleCreationRequestParameter();

  setItemModuleOid(value: number): ModuleCreationRequestParameter {

    this.ItemModuleOid = value;
    return this;

  }

  setQty(value: number): ModuleCreationRequestParameter {

    this.Qty = value;
    return this;

  }

  setLocationToOid(value: number): ModuleCreationRequestParameter {

    this.LocationToOid = value;
    return this;

  }

  setFinalize(value: boolean): ModuleCreationRequestParameter {

    this.Finalize = value;
    return this;

  }

}
