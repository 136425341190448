<div class="company-places-container">

  <div fxLayout="row wrap">

    <div fxFlex="33.33" fxFlex.sm="100" *ngFor="let configuration of configurations; let idx = index;">

      <app-company-place [configuration]="configuration" [idx]="idx + 1"></app-company-place>

    </div>

    <div fxFlex="33.33">

      <mat-card class="add-button" (click)="addNewConfiguration()">

        <mat-card-content>

          <button mat-fab>

            <mat-icon>add</mat-icon>

          </button>

        </mat-card-content>

      </mat-card>

    </div>

  </div>

</div>
