import {Component, TemplateRef, ViewChild} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {WarehouseService} from "../warehouse.service";
import {TranslateService} from "@ngx-translate/core";
import {LangDownloadFileConfiguration} from "../../lang-download-file/lang-download-file.configuration";
import {ReportDownloadParameter} from "../../../_core/classes/report-download.parameter";

@Component({
  selector: "app-warehouse-detail",
  templateUrl: "./warehouse-detail.component.html",
  styleUrls: ["./warehouse-detail.component.scss"]
})
export class WarehouseDetailComponent {

  @ViewChild("headerActions") headerActions: TemplateRef<any> | null = null;

  pId: string | null = null;

  langDownloadFileConfiguration: LangDownloadFileConfiguration = LangDownloadFileConfiguration
    .create()
    .setIcon('picture_as_pdf')
    .setColor('accent')
    .setButtonType('mat-mini-fab');

  constructor(
    private route: ActivatedRoute,
    private whService: WarehouseService,
    private translate: TranslateService,
    private router: Router) {

    this.pId = this.route.snapshot.paramMap.get("id");

  }

  ngAfterViewInit() {

    this.route
      .queryParams
      .subscribe(qp => {

        this.route
          .data
          .subscribe(data => {

            if (qp["Name"]) {

              setTimeout(() => {

                this.translate
                  .get("detailOf", {name: qp["Name"]})
                  .subscribe(result => data['title'] = result);

              })

            }
            data['headerActions'] = this.headerActions;
            data['urls'] = [];

          })

      });

  }

  reportQR() {

    if (!this.pId) return;

    this.whService
      .openQRCodeFromLocation({locationOid: +this.pId, locationName: this.route.snapshot.data['title']});

  }

  reportPDF(lang = 'it') {

    if (!this.pId) return;

    const parameter: ReportDownloadParameter = ReportDownloadParameter
      .create()
      .setLocationOid(+this.pId)
      .setTemplateLanguage(lang);

    this.whService
      .downloadLocationPDF(parameter);

  }

}
