import {ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {
  CtBinaryOperator,
  CtButtonConfiguration,
  CtControlConfiguration,
  CtControlTypes,
  CtFormConfiguration,
  CTGeneralService,
  CTModelDatatableFilter,
  CtModelDatatableOperators,
  CtSelectControlOptions,
  CtSelectControlValue,
  CtSortOrderDescriptorParameter,
  CtThemeTypes,
  MAT_RAISED_ACCENT,
  MAT_RAISED_WARN
} from "@ctsolution/ct-framework";
import {FormBuilder} from "@angular/forms";
import {CTWorkOrder} from "../../../../../_core/classes/ct-work-order";
import {CTMWorkOrderInformation} from "../../../../../_core/classes/ctm-work-order.information";
import {CTMWorkOrderRow} from "../../../../../_core/classes/ctm-work-order.row";
import {WorkOrderService} from "../../../work-order.service";
import {CTMWorkOrderViewType} from "../../../../../_core/enum/work-order-type";
import {WorkOrderImportParameter} from "../../../../../_core/classes/work-order-import.parameter";
import {ModuleCreateService} from "../../../../catalog/catalog-detail/module-create/module-create.service";
import {GeneralDataConfiguration} from "./general-data.configuration";
import {DataRequest, MethodEnum} from "@ctsolution/ct-webapi";

@Component({
  selector: 'ctm-general-data',
  templateUrl: './general-data.component.html',
  providers: [ModuleCreateService]
})
export class GeneralDataComponent {

  formConfiguration: CtFormConfiguration | null = null;
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Input() configuration: GeneralDataConfiguration | null = null;

  cancelButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_FORM.cancel")
    .setAction(() => this.general.back())
    .setMatherialOptions(MAT_RAISED_WARN);

  submitButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_FORM.save")
    .setAction(() => {

      this.formConfiguration?.form?.markAllAsTouched();
      this.onSubmit.emit();

    })
    .setMatherialOptions(MAT_RAISED_ACCENT);

  private workOrderStateLookup = this.workOrderService.getLookupControlDefaultConfiguration({
    name: 'WorkOrderState',
    controller: 'WorkOrderState',
    type: CtControlTypes.ENUMERABLE
  });
  private customerLookup = this.workOrderService.getLookupControlDefaultConfiguration({
    name: 'Customer',
    controller: 'Company',
    key: 'ReferenceName'
  })
    .configureOptions<CtSelectControlOptions>(options => {

      if (!options) options = CtSelectControlOptions.create();

      options
        .setLookupFilter(value => {

          const filters: CTModelDatatableFilter[] = [CTModelDatatableFilter.create().setField('IsCustomer').setValue(true).setOperatorType(CtBinaryOperator.Equal)];

          if (value) {

            filters.push(CTModelDatatableFilter.create().setField('ReferenceName').setValue(value).setOperatorType(CtBinaryOperator.Contains))

          }

          const operators = CtModelDatatableOperators.create().setFilters(filters);

          operators
            .Pagination
            ?.setSortOrders(new Array<CtSortOrderDescriptorParameter>(CtSortOrderDescriptorParameter.create('ReferenceName')))

          return operators;

        })

    })

  private addressLookup = this.workOrderService.getLookupControlDefaultConfiguration({
    name: "Address",
    controller: "CompanyPlace"
  })
    .setFlexSpacePercentage(100)
    .configureOptions<CtSelectControlOptions>(options => {

      if (!options) options = CtSelectControlOptions.create();

      options
        ?.setForceSelection(false)
        ?.setLookupFilter(value => {

          const filters: CTModelDatatableFilter[] = [];

          if (this.customerLookup.control?.valid) {

            const customerOid = this.customerLookup.control.value?.value;

            filters.push(CTModelDatatableFilter.create().setField('Company.Oid').setValue(customerOid).setOperatorType(CtBinaryOperator.Equal));

          } else {

            this.customerLookup.control?.markAsTouched();

          }

          if (value) {

            filters.push(CTModelDatatableFilter.create().setField('Name').setValue(value).setOperatorType(CtBinaryOperator.Contains))

          }

          const operators = CtModelDatatableOperators.create().setFilters(filters);

          operators
            .Pagination
            ?.setSortOrders(new Array<CtSortOrderDescriptorParameter>(CtSortOrderDescriptorParameter.create('Name')))

          return operators;

        })


    });

  private extraDataJsonControl = CtControlConfiguration
    .create()
    .setRemoteValueOptionsEndpoint(
      DataRequest
        .create()
        .setUrl(`/SpecificInfoWorkOrder/FieldsByType/1?hideSpinner=true`)
        .setMethod(MethodEnum.GET)
        .setBody({}))
    .setLabel('ExtraDataJSON')
    .setName('ExtraDataJSON')
    .setType(CtControlTypes.EXTRA_DATA);

  private genericQuotationControls = [
    this.workOrderService.getTextControl({
      name: 'Name',
      required: true,
      flexSpacePercentage: 33.33
    }).setLabel('ReferenceName'),
    this.workOrderService.getTextControl({name: 'Code', required: true, flexSpacePercentage: 33.33}),
    this.workOrderService.getTextControl({name: 'ExternalCode', required: false, flexSpacePercentage: 33.33}),
    this.workOrderStateLookup,
    this.customerLookup,
    this.addressLookup,
    this.workOrderService.getTextControl({name: 'Description'}).setType(CtControlTypes.TEXTAREA).setFlexSpacePercentage(100),
  ];

  private genericWorkOrderAdditionalControls = [
    this.workOrderService.getDateControlDefault({name: 'SupplyStartDate', required: false, flexSpacePercentage: 50}),
    this.workOrderService.getDateControlDefault({
      name: 'SupplyExpirationDate',
      required: false,
      flexSpacePercentage: 50
    })
  ]

  constructor(
    private cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private general: CTGeneralService,
    private workOrderService: WorkOrderService,
    private moduleCreateService: ModuleCreateService) {
  }

  ngAfterViewInit() {

    const controls = this.genericQuotationControls;

    switch (this.configuration?.workOrderType) {

      case CTMWorkOrderViewType.Standard:

        if (this.configuration.extraDataJsonEnabled) {

          this.genericWorkOrderAdditionalControls
            .push(this.extraDataJsonControl);

        }

        controls
          .push(...this.genericWorkOrderAdditionalControls);
        break;
      default:
        break;

    }

    this.formConfiguration = CtFormConfiguration
      .create(this.formBuilder.group({}))
      .setTheme(CtThemeTypes.MATERIAL)
      .setControls(controls);

    this.cdr.detectChanges();

  }

  import(event: any) {

    if (!this.configuration?.modelOid) return;

    const files = (event.target as HTMLInputElement).files ?? [];

    if (!files.length) return;

    const parameter: WorkOrderImportParameter = WorkOrderImportParameter
      .create()
      .setWorkOrderOid(+this.configuration.modelOid)
      .setFile(files[0]);

    this.moduleCreateService.open(parameter);

  }

  setup(model: CTWorkOrder<CTMWorkOrderInformation, CTMWorkOrderRow> | null) {

    if (!model) return;

    this.formConfiguration?.form?.patchValue(model);

    if (model.State) {

      this.formConfiguration?.form?.get('WorkOrderState')?.setValue(model.State.Oid);

    }

    if (model.ExpiredAt) {

      this.formConfiguration?.form?.get('SupplyExpirationDate')?.setValue(model.ExpiredAt);

    }

    if (model.StartAt) {

      this.formConfiguration?.form?.get('SupplyStartDate')?.setValue(model.StartAt);

    }

    if (model.Company) {

      this.customerLookup
        .setValue(
          CtSelectControlValue
            .create()
            .setLabel(model.Company.ReferenceName)
            .setValue(model.Company.Oid))

    }

    if (model.Address) {

      this.addressLookup
        .setValue(
          CtSelectControlValue
            .create()
            .setLabel(model.Address)
            .setValue(model.Address))

    }

    this.cdr.detectChanges();

  }

}
