import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MovementManagerComponent} from './movement-manager.component';
import {
  MovementManagerInsertModeComponent
} from './movement-manager-insert-mode/movement-manager-insert-mode.component';
import {ReactiveFormsModule} from "@angular/forms";
import {MatLegacyButtonModule} from "@angular/material/legacy-button";
import {MatIconModule} from "@angular/material/icon";
import {CtFrameworkModule} from "@ctsolution/ct-framework";
import {FlexLayoutModule} from "@angular/flex-layout";
import {MovementManagerRowsComponent} from './movement-manager-rows/movement-manager-rows.component';
import {TableModule} from "primeng/table";
import {MatLegacyCardModule} from "@angular/material/legacy-card";
import {MovementManagerAddItemModule} from "./movement-manager-add-item/movement-manager-add-item.module";
import {MatLegacyRadioModule} from "@angular/material/legacy-radio";
import {ButtonModule} from "primeng/button";
import {RippleModule} from "primeng/ripple";

@NgModule({
  declarations: [
    MovementManagerComponent,
    MovementManagerInsertModeComponent,
    MovementManagerRowsComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatLegacyButtonModule,
    MatIconModule,
    CtFrameworkModule,
    FlexLayoutModule,
    TableModule,
    MatLegacyCardModule,
    MovementManagerAddItemModule,
    MatLegacyRadioModule,
    ButtonModule,
    RippleModule
  ],
  exports: [MovementManagerComponent]
})
export class MovementManagerModule {
}
