<div class="movement-manager-add-item-container">

  <h1 mat-dialog-title>{{ 'Scheda movimentazione' | translate }}</h1>

  <div mat-dialog-content>

    <ct-form *ngIf="formConfiguration" [configuration]="formConfiguration"></ct-form>

  </div>

  <div mat-dialog-actions
       fxLayout="row wrap"
       fxLayoutAlign="space-between center">

    <div>

      <ct-button [configuration]="cancelButton"></ct-button>

    </div>

    <div>

      <ct-button [configuration]="addButton"></ct-button>

    </div>

  </div>

</div>
