import {CTMWorkOrderViewType} from "../../../../../_core/enum/work-order-type";

export class GeneralDataConfiguration {

  modelOid: string | null = null;
  importEnabled: boolean = false;
  extraDataJsonEnabled: boolean = false;

  private constructor(public workOrderType: CTMWorkOrderViewType) {
  }

  public static create = (workOrderType: CTMWorkOrderViewType) => new GeneralDataConfiguration(workOrderType);

  setModelOid(value: string | null) {

    this.modelOid = value;
    return this;

  }

  enableImport(value: boolean) {

    this.importEnabled = value;
    return this;

  }

  enableExtraDataJson(value: boolean) {

    this.extraDataJsonEnabled = value;
    return this;

  }

}
