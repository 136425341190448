import {Component} from '@angular/core';
import {CtModelConfiguration} from "@ctsolution/ct-framework";
import {UserRouteData} from "../../../_core/route-data/user.route-data";
import {DEFAULT_DATATABLE_CONFIGURATION} from "../../../_core/constants";
import {GeneralService} from "../../../_core/lib/general.service";
import {CtFieldCustomColumn} from "@ctsolution/ct-framework/lib/ct-datatable/_core/ct-field-custom.column";
import {CtManagerService} from "../../../_core/lib/ct-manager.service";

@Component({
  selector: 'app-user-list',
  template: `
    <ct-model *ngIf="configuration" [configuration]="configuration"></ct-model>`
})
export class UserListComponent {

  configuration: CtModelConfiguration<UserListComponent> | null = null;

  constructor(private general: GeneralService, private managerService: CtManagerService) {
  }

  ngAfterViewInit() {

    this.setup();

  }

  async setup() {

    const userHasMonitoringManagementEnabled = await this.managerService.hasEnabled.monitoring() ?? false;

    const fieldsCustomTemplates: CtFieldCustomColumn[] = [
      {prop: "DailyWorkingHours", visible: userHasMonitoringManagementEnabled},
      {prop: "WeeklyWorkingHours", visible: userHasMonitoringManagementEnabled},
      {prop: "WorkJob", visible: userHasMonitoringManagementEnabled},
    ];

    this.configuration = CtModelConfiguration
      .create<UserListComponent>()
      .setRouteData(UserRouteData())
      .setCTDatatableConfiguration(DEFAULT_DATATABLE_CONFIGURATION())
      .setOnCreate(() => this.general.navigateOnCTModelRouter(["user"], "create"))
      .setOnEdit(event => this.general.navigateOnCTModelRouter(["user"], "edit", {
        value: event,
        configuration: this.configuration
      }))
      .setFieldsCustomTemplates(fieldsCustomTemplates);
  }

}
