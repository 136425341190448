import {Injectable} from "@angular/core";
import {
  CtSerializerHelperService,
  CtWebapiService,
  DataRequest,
  MethodEnum
} from "@ctsolution/ct-webapi";
import {WorkOrderImportParameter} from "../classes/work-order-import.parameter";
import {WorkOrderRouteData} from "../route-data/work-order.route-data";

const CONTROLLER_REQUEST = (): DataRequest => new DataRequest().setController([WorkOrderRouteData().controller!]);

@Injectable()
export class WorkOrderController {

  constructor(private _webapi: CtWebapiService, private serializerHelperService: CtSerializerHelperService) {
  }

  importItems(parameter: WorkOrderImportParameter) {

    const request: DataRequest = CONTROLLER_REQUEST()
      .setAction("ImportItems")
      .setMethod(MethodEnum.POST)
      .setQueryParams({
        hideSpinner: true
      })
      .setBody(this.serializerHelperService.objectToFormData(parameter));

    return this._webapi
      .request(request);

  }

}
