import {Component, Inject, OnInit} from "@angular/core";
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from "@angular/material/legacy-dialog";
import {HandlingOriginEnum} from "../../../../../_core/enum/handling-origin.enum";
import {ItemHandling} from "../../../../../_core/classes/item-handling";
import {
  CtBinaryOperator,
  CtButtonConfiguration,
  CTModelDatatableFilter,
  MAT_RAISED_ACCENT,
  MAT_RAISED_PRIMARY
} from "@ctsolution/ct-framework";
import {CTManagerItemStock} from "../../../../../_core/classes/ct-manager-item-stock";
import {
  SelectedItemsConfiguration
} from "../../../../handling/order-handling-wizard/order-handling-wizard-step2/selected-items/selected-items.configuration";
import {
  ItemSelectionConfiguration
} from "../../../../handling/order-handling-wizard/order-handling-wizard-step2/item-selection/item-selection/item-selection.configuration";
import {
  ItemSelectionService
} from "../../../../handling/order-handling-wizard/order-handling-wizard-step2/item-selection/item-selection.service";

@Component({
  selector: "app-module-add-item",
  templateUrl: "./module-add-item.component.html",
  styleUrls: ["./module-add-item.component.scss"]
})
export class ModuleAddItemComponent implements OnInit {

  selectedItemsConfiguration: SelectedItemsConfiguration = SelectedItemsConfiguration
    .create()
    .setEditModeEnabled(true)
    .setTableMode('standard')

  itemSelectionConfiguration: ItemSelectionConfiguration = ItemSelectionConfiguration
    .create()
    .setOrigin(HandlingOriginEnum.Supplier);

  cancelButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("close")
    .setAction(() => this.onCancel())
    .setMatherialOptions(MAT_RAISED_ACCENT);

  submitButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_FORM.save")
    .setAction(() => this.submit())
    .setMatherialOptions(MAT_RAISED_PRIMARY);

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: ItemHandling,
    public itemSelectionService: ItemSelectionService,
    private dialogRef: MatDialogRef<ModuleAddItemComponent>) {
  }

  async ngOnInit() {

    this.itemSelectionConfiguration
      .setDefaultFilters([ // Devo cercare per tutti gli articoli diversi da questo
        CTModelDatatableFilter.create().setField("Oid").setValue(this.data.Module?.Oid).setOperatorType(CtBinaryOperator.NotEqual)
      ])

  }

  submit() {

    if (this.selectedItemsConfiguration.itemHandlings.length) {

      const handlings: ItemHandling[] = this.selectedItemsConfiguration.itemHandlings;

      // aggiungo il numero del modulo
      handlings
        .forEach(elm => elm
          .setModule(<CTManagerItemStock>{ItemOid: this.data.Module?.Oid ?? -1})
          .setQty(elm.StartQty));

      this.dialogRef
        .close(handlings);

    }

  }

  onCancel() {

    this.dialogRef.close();

  }

}
