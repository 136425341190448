import {Component, Inject} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef} from "@angular/material/legacy-dialog";
import {
  CtButtonConfiguration,
  CtControlConfiguration,
  CtThemeTypes,
  MAT_RAISED_ACCENT,
  MAT_RAISED_PRIMARY,
  MAT_RAISED_WARN
} from "@ctsolution/ct-framework";
import {Router} from "@angular/router";
import {ContactCompanyType} from "../../../../_core/enum/contact-company-type";
import {CompanyContactRelationService} from "../company-contact-relation.service";
import {ContactCompany} from "../../contact-company";

@Component({
  selector: 'app-company-contact-relation-edit',
  templateUrl: './company-contact-relation-edit.component.html',
  styleUrls: ['./company-contact-relation-edit.component.scss']
})
export class CompanyContactRelationEditComponent {

  form: FormGroup;

  nameControl: CtControlConfiguration = CtControlConfiguration
    .create()
    .setTheme(CtThemeTypes.MATERIAL)
    .setName('Name')
    .setLabel("Name")
    .setDisabled(true);

  surnameControl: CtControlConfiguration = CtControlConfiguration
    .create()
    .setTheme(CtThemeTypes.MATERIAL)
    .setName('Surname')
    .setLabel("Surname")
    .setDisabled(true);

  cancelButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_FORM.cancel")
    .setAction(() => this.onCancel())
    .setMatherialOptions(MAT_RAISED_WARN);

  editButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("editContact")
    .setAction(() => this.onEdit())
    .setMatherialOptions(MAT_RAISED_ACCENT);

  submitButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_FORM.save")
    .setAction(() => this.onUpdate())
    .setMatherialOptions(MAT_RAISED_PRIMARY);

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatLegacyDialogRef<any>,
    private router: Router,
    private companyContactRelationService: CompanyContactRelationService,
    @Inject(MAT_DIALOG_DATA) public parameter: ContactCompany
  ) {

    this.form = this.formBuilder.group({
      CurrentRole: new FormControl(null)
    });

  }

  ngOnInit() {

    this.setup();

  }

  setup() {

    this.nameControl
      .setFormParent(this.form);

    this.surnameControl
      .setFormParent(this.form);

    this.form
      .patchValue({
        ...this.parameter.Contacts,
        CurrentRole: this.parameter.Type === ContactCompanyType.Defined
      });

  }

  onCancel() {

    this.dialogRef.close();

  }

  onUpdate() {

    const parameter = this.parameter;

    parameter.Type = this.form.value.CurrentRole ? ContactCompanyType.Defined : ContactCompanyType.NotDefined;

    this.companyContactRelationService
      .companyContactRelationInsert(parameter)
      ?.subscribe((response) => this.dialogRef.close(response))

  }

  onEdit() {

    this.router
      .navigate(['/', 'contact', 'edit', this.parameter?.Contacts?.Oid!])
      .then(() => this.dialogRef.close())

  }

}
