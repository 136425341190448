import {Injectable} from '@angular/core';
import {MatLegacyDialog} from "@angular/material/legacy-dialog";
import {
  MovementManagerSearchItemComponent
} from "./movement-manager-search-item/movement-manager-search-item.component";
import {MovementManagerAddItemComponent} from "./movement-manager-add-item/movement-manager-add-item.component";
import {CTMMovementManagerItem} from "../ctm-movement-manager-item";
import {ComponentType} from "@angular/cdk/overlay";
import {CTManagerItem} from "../../../../../../_core/classes/ct-manager-item";
import {MovementManagerAddItemConfiguration} from "./movement-manager-add-item/movement-manager-add-item.configuration";

@Injectable()
export class MovementManagerAddItemService {

  constructor(private dialog: MatLegacyDialog) {
  }

  openDialogAndAddItem<TResult>(component: ComponentType<unknown>, config = {}): Promise<TResult | null | undefined> {
    const dialogRef = this.dialog.open(component, config);

    return new Promise<TResult | null | undefined>((resolve, reject) => {
      dialogRef.afterClosed().subscribe(result => resolve(result), error => reject(error));
    });
  }

  async searchItem() {

    const item = await this.openDialogAndAddItem<CTManagerItem>(MovementManagerSearchItemComponent, {minWidth: 500});

    if (!item) return;

    return this.addItem(item)

  }

  addItem(value: CTManagerItem | null = null) {

    const configuration: MovementManagerAddItemConfiguration = MovementManagerAddItemConfiguration
      .create()
      .setItem(value);

    return this.openDialogAndAddItem<CTMMovementManagerItem>(MovementManagerAddItemComponent, {
      minWidth: 500,
      data: configuration
    });

  }

}
