import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {WorkOrderEditComponent} from "./work-order-edit/work-order-edit.component";
import {WorkOrderListComponent} from "./work-order-list/work-order-list.component";
import {WorkOrderEditModule} from "./work-order-edit/work-order-edit.module";
import {WorkOrderListModule} from "./work-order-list/work-order-list.module";
import {CTMWorkOrderViewType} from "../../_core/enum/work-order-type";
import {WorkOrderService} from "./work-order.service";

@NgModule({
  providers: [WorkOrderService],
  imports: [
    CommonModule,
    WorkOrderEditModule,
    WorkOrderListModule,
    RouterModule.forChild([
      {
        path: "",
        children: [
          {
            path: "quotations",
            children: [
              {
                path: "",
                redirectTo: "list",
                pathMatch: "full"
              },
              {
                path: "create",
                component: WorkOrderEditComponent,
                data: {
                  type: CTMWorkOrderViewType.Quotation
                }
              },
              {
                path: "edit/:id",
                component: WorkOrderEditComponent,
                data: {
                  type: CTMWorkOrderViewType.Quotation
                }
              },
              {
                path: "list",
                component: WorkOrderListComponent,
                data: {
                  type: CTMWorkOrderViewType.Quotation
                }
              }
            ]
          },
          {
            path: "",
            children: [
              {
                path: "",
                redirectTo: "list",
                pathMatch: "full"
              },
              {
                path: "create",
                component: WorkOrderEditComponent
              },
              {
                path: "edit/:id",
                component: WorkOrderEditComponent
              },
              {
                path: "list",
                component: WorkOrderListComponent
              }
            ]
          }
        ]
      }
    ])
  ]
})
export class WorkOrderModule {
}
