import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GeneralDataComponent} from "./general-data.component";
import {MatLegacyCardModule} from "@angular/material/legacy-card";
import {CtButtonModule, CtFormModule} from "@ctsolution/ct-framework";
import {MatIconModule} from "@angular/material/icon";
import {FlexLayoutModule} from "@angular/flex-layout";
import {MatLegacyButtonModule} from "@angular/material/legacy-button";

@NgModule({
  declarations: [GeneralDataComponent],
  imports: [
    CommonModule,
    MatLegacyCardModule,
    CtFormModule,
    CtButtonModule,
    MatIconModule,
    FlexLayoutModule,
    MatLegacyButtonModule
  ],
  exports: [GeneralDataComponent]
})
export class GeneralDataModule {
}
