import { CTManagerLocation } from "./ct-manager-location";
import { CTBase } from "@ctsolution/ct-base";
import { CTManagerItem } from "./ct-manager-item";

export class CTManagerItemStock extends CTBase<CTManagerItemStock> {
  Code: string | null = null;
  Location: CTManagerLocation | null = null;
  ItemOid: number | null = null;
  ItemCode: string | null = null;
  ItemName: string | null = null;
  ItemDescription: string | null = null;
  Stock: number | null = null;
  UMOid: number | null = null;
  UMSymbol: string | null = null;

  constructor();
  constructor(model?: CTManagerItemStock)
  constructor(model?: CTManagerItemStock) {

    super();

    if (model) this.getClass(model);

  }

  public static create = (model?: CTManagerItemStock): CTManagerItemStock => new CTManagerItemStock(model);

  public static createByCTManagerItem(value: CTManagerItem | null): CTManagerItemStock {

    if (!value) return this.create();

    const newItemStock = this.create({

      ItemCode: value.Code,
      ItemName: value.Name,
      ItemOid: value.Oid

    } as CTManagerItemStock);

    if (value.DefaultUnitOfMeasure) {

      newItemStock.UMOid = value.DefaultUnitOfMeasure.Oid;
      newItemStock.UMSymbol = value.DefaultUnitOfMeasure.Symbol;

    }

    return newItemStock;

  }

}
