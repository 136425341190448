import {Component, EventEmitter, Input, Output} from '@angular/core';
import {LangDownloadFileConfiguration} from "./lang-download-file.configuration";

@Component({
  selector: 'app-lang-download-file',
  templateUrl: './lang-download-file.component.html'
})
export class LangDownloadFileComponent {

  @Input() configuration: LangDownloadFileConfiguration | null = null;
  @Output() onDownload: EventEmitter<string> = new EventEmitter<string>();

  public languages: any[] = [
    {
      language: "Italiano",
      code: "it",
      type: "IT",
      icon: "it"
    },
    {
      language: "English",
      code: "en",
      type: "US",
      icon: "us"
    }
  ];

}
