import {Injectable} from "@angular/core";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {ModuleCreateComponent} from "./module-create.component";
import {ModuleCreationRequestParameter} from "../../../../_core/controllers/item.controller";
import {WorkOrderImportParameter} from "../../../../_core/classes/work-order-import.parameter";

@Injectable()
export class ModuleCreateService {

  constructor(private dialog: MatDialog) {
  }

  open(parameter: ModuleCreationRequestParameter | WorkOrderImportParameter) {

    return this.dialog
      .open(ModuleCreateComponent, {
        data: parameter,
        maxWidth: 650,
        panelClass: "module-create-container"
      });

  }

}
