import {Injectable} from '@angular/core';
import {MatLegacyDialog} from "@angular/material/legacy-dialog";
import {UserWorkingHoursExportParameter} from "../../../_core/classes/user-working-hours-export.parameter";
import {DataRequest, MethodEnum} from "@ctsolution/ct-webapi";
import {FileService} from "../../../_core/lib/file.service";
import {environment} from "../../../../environments/environment";
import {UserWorkingHoursExportComponent} from "./user-working-hours-export/user-working-hours-export.component";

@Injectable()
export class UserWorkingHoursExportService {

  constructor(
    private matLegacyDialog: MatLegacyDialog,
    private fileService: FileService) {
  }

  openDialog() {

    this.matLegacyDialog
      .open(UserWorkingHoursExportComponent, {disableClose: true});

  }

  exportExcel(parameter: UserWorkingHoursExportParameter, exportName = `${environment.projectName}_WorkingHoursReport`) {

    const request: DataRequest = new DataRequest()
      .setController(["UserWorkingHours"])
      .setAction('ExportExcel')
      .setBody(parameter)
      .setMethod(MethodEnum.POST);

    this.fileService
      .getFile(request)
      .subscribe(result => {

        this.fileService.blobDownload(result, exportName)

        return result;

      })

  }


}
