import {Component, Inject, OnInit, ViewChild} from "@angular/core";
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from "@angular/material/legacy-dialog";
import {CtButtonConfiguration, MAT_RAISED_PRIMARY, MAT_RAISED_WARN, SnackbarService} from "@ctsolution/ct-framework";

import {CtWebapiGenericResponse} from "@ctsolution/ct-webapi";
import {ModuleCreateFormComponent} from "./module-create-form/module-create-form.component";
import {
  ItemController,
  ModuleCreationRequestParameter,
  ModuleCreationRequestResult
} from "../../../../_core/controllers/item.controller";
import {WorkOrderImportParameter} from "../../../../_core/classes/work-order-import.parameter";
import {WorkOrderController} from "../../../../_core/controllers/work-order.controller";
import {Observable} from "rxjs";

@Component({
  selector: "app-module-create",
  templateUrl: "./module-create.component.html",
  styleUrls: ["./module-create.component.scss"],
  providers: [ItemController, WorkOrderController]
})
export class ModuleCreateComponent {

  @ViewChild('moduleCreateForm') moduleCreateForm: ModuleCreateFormComponent | null = null;

  moduleCreationRequestResult: ModuleCreationRequestResult | null = null;

  cancelButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_FORM.cancel")
    .setAction(() => this.onCancel())
    .setMatherialOptions(MAT_RAISED_WARN);

  previewButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("generateMovementsLabel")
    .setAction(() => this.onPreview())
    .setMatherialOptions(MAT_RAISED_PRIMARY);

  finalizeButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("confirm")
    .setAction(() => this.onPreview(true))
    .setMatherialOptions(MAT_RAISED_PRIMARY);

  viewModel = {
    title: "moduleGenerationLabel"
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public parameter: ModuleCreationRequestParameter | WorkOrderImportParameter,
    private itemController: ItemController,
    private workOrderController: WorkOrderController,
    private dialogRef: MatDialogRef<ModuleCreateComponent>,
    private _snackbar: SnackbarService
  ) {

    this.setupModel();

  }

  setupModel() {

    if (this.parameter instanceof WorkOrderImportParameter) {

      this.viewModel.title = "workOrderImportLabel";

    }

  }

  onCancel() {

    this.dialogRef
      .close();

  }

  onPreview(submit = false) {

    const form = this.moduleCreateForm?.form;


    form?.markAllAsTouched();

    if (!form) return;

    if (form.valid) {

      if (submit) this.parameter.setFinalize(true);

      let observable: Observable<any> | null = null;

      const locationOid = form.value.LocationToOid.value;

      if (this.parameter instanceof WorkOrderImportParameter) {

        this.parameter
          .setLocationOid(locationOid);

        observable = this.workOrderController
          .importItems(this.parameter);

      } else {

        this.parameter
          .setLocationToOid(locationOid)
          .setQty(form.value.qty)

        observable = this.itemController
          .moduleCreationRequest(this.parameter);

      }

      observable
        ?.subscribe((response: CtWebapiGenericResponse<ModuleCreationRequestResult>) => {

          this.moduleCreationRequestResult = response.Result;

          if (submit) {

            this._snackbar.open("CT_MESSAGES.success_operation");

            this.dialogRef
              .close()

          }

        });

    }

  }

}
