import {Component, OnInit, Type} from "@angular/core";
import {environment} from "../../../../environments/environment";
import {WorkOrderDefaultEditComponent} from "./work-order-default-edit/work-order-default-edit.component";

@Component({
  selector: "app-work-order-edit",
  template: `
    <ng-container *ngIf="componentToLoad">

      <ng-container *ngComponentOutlet="componentToLoad"></ng-container>

    </ng-container>`
})
export class WorkOrderEditComponent<T> implements OnInit {

  componentToLoad: Type<T> | typeof WorkOrderDefaultEditComponent | null = null;

  ngOnInit() {

    this.loadCustomModule()
      .then((component: Type<T> | null) => {

        this.componentToLoad = component ?? WorkOrderDefaultEditComponent;
        if (!component) console.warn('Error loading the custom work order edit component. Make sure "projectBaseDir" is set correctly in the environment file.'); // Use warn instead of error for non-critical issues

      });

  }

  async loadCustomModule() {

    try {

      if (!(<any>environment).projectBaseDir) return null; // Or throw a specific error for customized handling

      await import(`projects/${(<any>environment).projectBaseDir}/src/app/work-order/work-order.module`);
      const {WorkOrderEditComponent} = await import((`projects/${(<any>environment).projectBaseDir}/src/app/work-order/work-order-edit/work-order-edit.component`));
      return WorkOrderEditComponent;

    } catch (error) {

      console.error('Error loading custom work order component:', error);
      return null; // Or throw a specific error to handle upstream

    }

  }

}
