import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CompanyContractComponent} from './company-contract.component';
import {CtModelModule} from "@ctsolution/ct-framework";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [
    CompanyContractComponent
  ],
  exports: [
    CompanyContractComponent
  ],
  imports: [
    CommonModule,
    CtModelModule,
    TranslateModule
  ]
})
export class CompanyContractModule {
}
