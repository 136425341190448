import {Component} from '@angular/core';
import {CTMMovementManagerItem} from "../ctm-movement-manager-item";

@Component({
  selector: 'app-movement-manager-rows',
  templateUrl: './movement-manager-rows.component.html',
  styleUrls: ['./movement-manager-rows.component.scss']
})
export class MovementManagerRowsComponent {

  protected items: Array<CTMMovementManagerItem> = new Array<CTMMovementManagerItem>();

  addItem(value: CTMMovementManagerItem) {

    this.items.push(value);

  }

  onRowDelete(idx: number) {

    this.items.splice(idx, 1)

  }

}
