import {Component, Inject, ViewChild} from "@angular/core";
import {
  CtBinaryOperator,
  CtButtonConfiguration, CtDatatableComponent,
  CtDatatableConfiguration,
  CTDatatablePaginationParameter, CtDatatableSortParameter, CtFormContainerHelper,
  CtModelConfiguration,
  CTModelDatatableFilter, CtModelDatatableOperators, CtModelService, CtSortOrderDescriptorParameter,
  MAT_RAISED_PRIMARY,
  MAT_RAISED_WARN
} from "@ctsolution/ct-framework";
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from "@angular/material/legacy-dialog";
import {ItemSelectionConfiguration} from "./item-selection.configuration";
import {ITEM_STOCK_SELECTION_COLUMNS} from "./item-stock.selection-columns";
import {ITEM_SELECTION_COLUMNS} from "./item.selection-column";
import {HandlingOriginEnum} from "../../../../../../_core/enum/handling-origin.enum";
import {ItemState} from "../../../../../../_core/enum/item-state";
import {CTManagerItem} from "../../../../../../_core/classes/ct-manager-item";
import {CTManagerItemStock} from "../../../../../../_core/classes/ct-manager-item-stock";
import {ItemRouteData} from "../../../../../../_core/route-data/item.route-data";
import {LocationStockRouteData} from "../../../../../../_core/route-data/location-stock.route-data";

@Component({
  selector: "app-item-selection",
  templateUrl: "./item-selection.component.html",
  styleUrls: ["./item-selection.component.scss"]
})
export class ItemSelectionComponent { // TODO: Questa tabella ha delle customizzazione particolari, però è riconducibile alla ct-datatable-container ... pensare di centralizzarla piu avanti?

  @ViewChild(CtDatatableComponent) datatable: CtDatatableComponent | null = null;

  cancelButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_FORM.cancel")
    .setAction(() => this.onCancel())
    .setMatherialOptions(MAT_RAISED_WARN);

  submitButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_FORM.save")
    .setAction(() => this.onSubmit())
    .setMatherialOptions(MAT_RAISED_PRIMARY);

  list: CtDatatableConfiguration = CtDatatableConfiguration
    .create()
    .setScrollbarH(true)
    .setFiltersEnabled(true)
    .setExternalSorting(true);

  private Filters: any | null = null;
  private Sortings: CtDatatableSortParameter | null = null;
  configuration: CtModelConfiguration<any> | null = null;

  get isSupplier(): boolean {

    return this.data.origin === HandlingOriginEnum.Supplier;

  }

  constructor(
    private modelService: CtModelService<any>,
    @Inject(MAT_DIALOG_DATA) private data: ItemSelectionConfiguration,
    private dialog: MatDialogRef<ItemSelectionComponent>) {

    this.list
      .setSelectionType(this.data.selectionType)
      .setColumns(this.isSupplier ? ITEM_SELECTION_COLUMNS : ITEM_STOCK_SELECTION_COLUMNS)
      .setController('CTMItemSelection');

    this.configuration = CtModelConfiguration
      .create()
      .setRouteData(this.isSupplier ? ItemRouteData() : LocationStockRouteData())

  }

  ngAfterViewInit(): void {

    setTimeout(() => {

      window.dispatchEvent(new Event("resize"));

    }, 250);

  }

  getFilters(): CTModelDatatableFilter[] {

    let filters: CTModelDatatableFilter[] = new Array<CTModelDatatableFilter>();

    switch (this.data.origin) {

      case HandlingOriginEnum.Supplier:

        filters = [

          CTModelDatatableFilter
            .create()
            .setField("State")
            .setValue(ItemState.Enabled)
            .setOperatorType(CtBinaryOperator.Equal)

        ];

        if (this.data.from) {

          filters.push(
            CTModelDatatableFilter
              .create()
              .setField("Supplier.Oid")
              .setValue(this.data.from)
              .setOperatorType(CtBinaryOperator.Equal));

        }

        break;

      case HandlingOriginEnum.Location:

        filters = [

          CTModelDatatableFilter
            .create()
            .setField("Location.Oid")
            .setValue(this.data.from)
            .setOperatorType(CtBinaryOperator.Equal)

        ];

        break;

    }

    if (this.data.searchText) {

      filters
        .push(CTModelDatatableFilter
          .create()
          .setField("ItemCodeName")
          .setValue(this.data.searchText)
          .setOperatorType(CtBinaryOperator.Contains));

    }

    if (this.data.defaultFilters) {

      filters = filters.concat(this.data.defaultFilters)

    }

    return filters;

  }

  onSort(event: CtDatatableSortParameter) {

    this.Sortings = CtFormContainerHelper.create().setupCTLookupInfoSortingValues(this.configuration!, event);

    this.fetchData();

  }

  fetchData(pagination?: CTDatatablePaginationParameter): void {

    this.list.setLoadingIndicator(true);

    this.caller(pagination)
      .subscribe(response => {

        this.list
          .setCount(response?.Result?.TotalElement ?? 0)
          .setRows((response?.Result?.DataSource ?? []) as CTManagerItemStock[])
          .setLoadingIndicator(false);

      });

  }

  private caller(pagination?: CTDatatablePaginationParameter) {

    const body: CtModelDatatableOperators = CtModelDatatableOperators.create()
      .setFilters()

    if (pagination) {

      body
        .setPagination(pagination)

    }

    if (this.Filters) {

      body
        .setFiltersByKeyValueObject(this.Filters, this.configuration!.CTFieldInfos ?? []);

    }

    if (!body.Filters) {

      body
        .setFilters(new Array<CTModelDatatableFilter>())

    }

    body
      .Filters
      .push(...this.getFilters())

    if (body.Filters.length && !pagination) { // se ho dei filtri presenti e pagination non è valorizzata (quindi non provengo dal click del paginatore) riparto da pagina 1

      body
        .Pagination
        ?.setPage(1);

    }

    if (this.Sortings) {

      body
        .Pagination
        ?.setSortOrdersByCtDatatableSortParameter(this.Sortings);

    } else {

      body
        .Pagination
        ?.setSortOrders(
          new Array<CtSortOrderDescriptorParameter>(CtSortOrderDescriptorParameter.create(this.isSupplier ? 'Name' : 'ItemName')))

    }
    
    return this.modelService
      .getList(this.configuration!, body);

  }

  onCancel() {

    this.dialog
      .close();

  }

  search(event: { [key: string]: any; } | null = null) {

    this.Filters = event ? CtFormContainerHelper.create().setupCTLookupInfoControlValues(this.configuration!, event, true) : null;

    if (!this.Filters) {

      this.datatable
        ?.form
        .reset({}, {emitEvent: false})

    } else {

      this.datatable
        ?.form
        .patchValue(this.Filters ?? {}, {emitEvent: false});

    }

    this.fetchData();

  }

  onSubmit() {

    let selected = this.list.selected;

    if (this.isSupplier) {

      selected = ((selected ?? []) as CTManagerItem[])
        .map(item => CTManagerItemStock.createByCTManagerItem(item));

    }

    this.dialog
      .close(selected);

  }

}
