import {TableColumn} from "@swimlane/ngx-datatable";
import {CtControlConfiguration} from "@ctsolution/ct-framework";

export const ITEM_STOCK_SELECTION_COLUMNS: (TableColumn | any)[] = [
  {
    prop: "ItemCode",
    name: "Codice",
    width: 100,
    maxWidth: 100,
    control: CtControlConfiguration
      .create()
      .setName('Code')
      .setLabel('Codice'),
    ColumnBuilder: {
      FilterEnabled: true
    }
  },
  {
    prop: "ItemName",
    name: "Nome",
    width: 300,
    control: CtControlConfiguration
      .create()
      .setName('ItemName')
      .setLabel("Nome"),
    ColumnBuilder: {
      FilterEnabled: true
    }
  },
  {
    prop: "ItemDescription",
    name: "Descrizione",
    width: 300,
    control: CtControlConfiguration
      .create()
      .setName('ItemDescription')
      .setLabel("Descrizione"),
    ColumnBuilder: {
      FilterEnabled: true
    }
  },
  {
    prop: "Stock",
    name: "Quantità"
  },
  {
    prop: "UMSymbol",
    name: "UM"
  }
];
