import {Component, Input} from '@angular/core';
import {
  CtBinaryOperator,
  CtModelConfiguration,
  CTModelDatatableFilter, CtModelOperator,
  CtModelType
} from "@ctsolution/ct-framework";
import {ContactCompanyRouteData} from "../../_core/route-data/contact-company.route-data";
import {CompanyContactConfiguration} from "./company-contact.configuration";

@Component({
  selector: 'app-company-contact',
  template: `
    <div>
      <h5 *ngIf="title"> {{ title | translate }} </h5>
      <ct-model *ngIf="modelConfiguration" [configuration]="modelConfiguration"></ct-model>
    </div>`
})
export class CompanyContactComponent {

  modelConfiguration: CtModelConfiguration<any> | null = null;
  @Input() configuration: CompanyContactConfiguration | null = null;
  @Input() title: string = 'references';

  constructor() {
  }

  ngAfterViewInit() {

    this.setup();

  }

  setup() {

    const configuration = CtModelConfiguration
      .create<any>()
      .setRouteData(ContactCompanyRouteData().setModelType(CtModelType.DATATABLE))
      .setFilterable(this.configuration?.Filterable ?? false)
      .setOnCreate(this.configuration?.OnCreate)
      .setOnEdit(this.configuration?.OnEdit)
      .setFieldsCustomTemplates(this.configuration?.FieldsCustomTemplates ?? [])

    configuration
      .initialized()
      .subscribe(() => configuration?.setOperations(this.configuration?.Operations ?? []))

    configuration
      .filterValues
      .addConstantFilter(
        CTModelDatatableFilter
          .create()
          .setField(this.configuration?.CompanyContactFilter?.Field ?? null)
          .setValue(this.configuration?.CompanyContactFilter?.Value)
          .setOperatorType(CtBinaryOperator.Equal)
      )

    this.modelConfiguration = configuration;

  }

}
