import {Component} from '@angular/core';
import {
  CtBinaryOperator,
  CtModelConfiguration,
  CTModelDatatableFilter,
  CtModelOperator,
  CtModelRouteData,
  CtModelType
} from "@ctsolution/ct-framework";
import {ActivatedRoute} from "@angular/router";
import {CompanyService} from "../company.service";
import {CompanyRouteData} from "../../../_core/route-data/company.route-data";
import {CustomerType} from "../../../_core/enum/customer-type";
import {CompanyContactConfiguration, CompanyContactFilter} from "../../company-contact/company-contact.configuration";
import {ContactCompany} from "../../company-contact/contact-company";
import {ContactCompanyType} from "../../../_core/enum/contact-company-type";
import {
  CompanyContactRelationService
} from "../../company-contact/company-contact-relation/company-contact-relation.service";
import {CtManagerService} from "../../../_core/lib/ct-manager.service";

@Component({
  selector: 'app-company-edit',
  template: `
    <ct-model *ngIf="configuration" [configuration]="configuration"></ct-model>

    <ng-container *ngIf="enableMasterDetail">

      <div class="ct-manager-master-detail-container">

        <app-company-contact
          *ngIf="companyContactConfiguration"
          [configuration]="companyContactConfiguration"
          [title]="'company.references'"></app-company-contact>

      </div>

      <app-company-information [masterConfiguration]="configuration"></app-company-information>

      <app-company-place-list [masterConfiguration]="configuration"></app-company-place-list>

      <div class="ct-manager-master-detail-container">

        <app-company-contract [masterConfiguration]="configuration"></app-company-contract>

      </div>

    </ng-container>
  `
})
export class CompanyEditComponent {

  companyModelRouteData: CtModelRouteData = CompanyRouteData()
    .setModelType(CtModelType.FORM);

  configuration: CtModelConfiguration<any> | null = null;
  companyContactConfiguration: CompanyContactConfiguration | null = null;

  get enableMasterDetail(): boolean {

    return !!this.configuration?.RouteData?.id;

  }

  constructor(
    private route: ActivatedRoute,
    private companyService: CompanyService,
    private managerService: CtManagerService,
    private companyContactRelationService: CompanyContactRelationService) {

    this.setupConfiguration()

  }

  setupConfiguration() {

    const pId: string | null = this.route.snapshot.paramMap.get("id");

    if (pId) {

      this.companyModelRouteData.setId(+pId);

      this.setupCompanyContactConfiguration();

    }

    const configuration = CtModelConfiguration
      .create<any>()
      .setRouteData(this.companyModelRouteData);

    const tpValue: string | null = this.route.snapshot.paramMap.get("type");

    if (!tpValue) return;

    const isSupplier = this.companyService.getCustomerTypeByRouteValue(tpValue) === CustomerType.Supplier;

    configuration
      .filterValues
      .addConstantFilter(
        CTModelDatatableFilter
          .create()
          .setField(isSupplier ? 'IsSupplier' : 'IsCustomer')
          .setValue(true)
          .setOperatorType(CtBinaryOperator.Equal)
      )

    if (isSupplier) {

      this
        .route
        .data
        .subscribe(data => {
          data["title"] = "CT_MENU.supplier";
        });

    }

    this.configuration = configuration;

  }

  async setupCompanyContactConfiguration() {

    const CRM = await this.managerService.hasEnabled.CRM() ?? false;

    if (!CRM) return;

    this.companyContactConfiguration = CompanyContactConfiguration
      .create()
      .setOperations([CtModelOperator.Create, CtModelOperator.Update, CtModelOperator.Delete])
      .setCompanyContactFilter(
        CompanyContactFilter
          .create()
          .setField('Companies.Oid')
          .setValue(this.companyModelRouteData.id)
      )
      .setOnCreate(() => this.addContact())
      .setOnEdit(async contactRelation => {

        const returnData = await this.companyContactRelationService.companyContactRelationEdit(contactRelation)

        if (returnData) window.location.reload();

      })
      .setFieldsCustomTemplates([
        {prop: 'Companies', visible: false},
        {prop: 'CompaniesType', visible: false}
      ]);

  }

  private async addContact() {

    const oid = await this.companyContactRelationService.openBinder();

    if (oid) {

      const modelValue: ContactCompany = ContactCompany
        .create()
        .setContactsOid(oid)
        .setCompaniesOid(this.companyModelRouteData?.id ?? -1)
        .setType(ContactCompanyType.Defined);

      this.companyContactRelationService
        .companyContactRelationInsert(modelValue)
        ?.subscribe(() => window.location.reload());

      // dovrei usare la navigazione sul router, però non sta funzionando correttamente ... per ora facciamo refresh forzato

    }

  }

}
