<mat-card>

  <mat-card-header>
    <mat-card-title>{{ 'dashboard.preparingMovementsLabel' | translate }}</mat-card-title>
  </mat-card-header>

  <mat-card-content>

    <p-table
      #table
      class="movement-manager-rows-table"
      [value]="items">

      <ng-template pTemplate="header">

        <tr>

          <th> {{ 'ItemCode' | translate }}</th>
          <th> {{ 'ItemDescription' | translate }}</th>
          <th> {{ 'Amount' | translate }}</th>
          <th> {{ 'Discount' | translate }}</th>
          <th> {{ 'IVA' | translate }}</th>
          <th></th>

        </tr>

      </ng-template>

      <ng-template pTemplate="body" let-item let-ri="rowIndex">

        <tr>

          <td>

            {{ item.Code }}

          </td>

          <td>

            {{ item.Description }}

          </td>

          <td>

            {{ item.Amount }}

          </td>

          <td>

            {{ item.Discount ?? '-' }}

          </td>

          <td>

            {{ item.IVA ? (item.IVA + '%') : '-' }}

          </td>

          <td>

            <div class="flex align-items-center justify-content-center gap-2">

              <button
                pButton
                pRipple
                type="button"
                icon="pi pi-trash"
                (click)="onRowDelete(ri)" class="p-button-rounded p-button-text"></button>

            </div>

          </td>

        </tr>

      </ng-template>

      <ng-template pTemplate="emptymessage" let-columns>

        <p> {{ 'CT_MESSAGES.no_available_data' | translate }}</p>
      </ng-template>

    </p-table>

  </mat-card-content>

</mat-card>
