import {CTBase} from "@ctsolution/ct-base";
import {UnitsOfMeasurements} from "./units-of-measurements";
import {CTManagerItemStock} from "./ct-manager-item-stock";

export class CTManagerItem extends CTBase<CTManagerItem> {

  Oid: number | null = null;
  Name: string | null = null;
  Code: string | null = null;
  DefaultUnitOfMeasure: UnitsOfMeasurements | null = null;

  constructor();
  constructor(model?: CTManagerItem)
  constructor(model?: CTManagerItem) {

    super();

    if (model) this.getClass(model);

  }

  public static create = (model?: CTManagerItem): CTManagerItem => new CTManagerItem(model);

  public static createFromCTManagerItemStock = (model?: CTManagerItemStock): CTManagerItem => {

    return new CTManagerItem({
      Oid: model?.ItemOid,
      Name: model?.ItemName,
      Code: model?.ItemCode,
      DefaultUnitOfMeasure: {
        Oid: model?.UMOid
      }
    } as CTManagerItem)

  };

}
