import {Component, EventEmitter, Output, ViewChild} from '@angular/core';
import {MovementManagerConfiguration} from "./movement-manager.configuration";
import {MovementManagerInsertModeEnum} from "./movement-manager-insert-mode/movement-manager-insert-mode.enum";
import {MovementManagerAddItemService} from "./movement-manager-add-item/movement-manager-add-item.service";
import {MovementManagerRowsComponent} from "./movement-manager-rows/movement-manager-rows.component";
import {CTMMovementManagerItem} from "./ctm-movement-manager-item";

@Component({
  selector: 'ctm-movement-manager',
  templateUrl: './movement-manager.component.html'
})
export class MovementManagerComponent {

  @ViewChild('movementManagerRowsComponent') movementManagerRowsComponent: MovementManagerRowsComponent | null = null;
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  configuration: MovementManagerConfiguration | null = null;

  constructor(private movementManagerAddItemService: MovementManagerAddItemService) {
  }

  async insert(mode: MovementManagerInsertModeEnum) {

    let item: CTMMovementManagerItem | null | undefined = null;

    switch (mode) {

      case MovementManagerInsertModeEnum.Stock:

        item = await this.movementManagerAddItemService.searchItem();
        break;

      case MovementManagerInsertModeEnum.FreeInput:

        item = await this.movementManagerAddItemService.addItem();
        break;

    }

    if (item) {

      this.movementManagerRowsComponent
        ?.addItem(item);

    }

  }

}
