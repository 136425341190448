import {Injectable} from '@angular/core';
import {CustomerType} from "../../_core/enum/customer-type";
import {CtBinaryOperator, CTModelDatatableFilter} from "@ctsolution/ct-framework";

@Injectable()
export class CompanyService {

  getCustomerTypeByRouteValue(typePathValue: string | null): CustomerType | null {

    switch (typePathValue) {

      case "supplier":
        return CustomerType.Supplier;
      case "private":
      default:
        return CustomerType.Private;

    }

  }

}
