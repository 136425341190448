import {Component, Input} from '@angular/core';
import {
  StatusToggleComponentButtonConfiguration,
  StatusToggleComponentConfiguration
} from "./status-toggle-component.configuration";

@Component({
  selector: 'app-status-toggle-component',
  templateUrl: './status-toggle-component.component.html',
  styleUrls: ['./status-toggle-component.component.scss']
})
export class StatusToggleComponentComponent<T> {

  @Input() configuration: StatusToggleComponentConfiguration<T> = StatusToggleComponentConfiguration.create<T>();

  getColor = (value: StatusToggleComponentButtonConfiguration<T>) => this.incrementalDisable(value) ? `#0000001F` : `var(--handlingState${value.value})`;

  setValue(value: T) {

    if (this.configuration.control?.disabled) return;

    if (this.configuration.control?.value === value) return;

    this.configuration
      .control
      ?.setValue(value);

  }

  incrementalDisable(button: StatusToggleComponentButtonConfiguration<T>) {

    if (this.configuration?.disabled) return true;

    if (this.configuration?.incremental) {

      const currentButtonIndex = this.configuration.values.findIndex((btn) => btn.value === this.configuration.control?.value);

      // Verifica se l'elemento successivo esiste nell'array
      const nextButton = this.configuration.values[currentButtonIndex + 1];

      // Disabilita il pulsante se non corrisponde al pulsante corrente o se l'elemento successivo non esiste
      return button.value !== this.configuration.control?.value && (!nextButton || nextButton.value !== button.value);

    }

    return false; // Se non è incrementale, non disabilitare il pulsante

  }

}
