<mat-card>
  <mat-card-header>
    <mat-card-subtitle>{{ configuration?.subtitle | translate }}</mat-card-subtitle>
    <mat-card-title> {{ configuration?.title | translate }}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p [innerHTML]="configuration?.description | translate"></p>
    <mat-divider></mat-divider>
    <ct-file-uploader
      #Uploader
      [configuration]="configuration?.CTFileUploaderConfiguration"></ct-file-uploader>

    <mat-card-actions
      fxLayout="row wrap"
      fxLayoutAlign="space-between center">

      <div>

        <button *ngIf="configuration?.closeActionEnabled"
                mat-raised-button (click)="close.emit()"> {{ "close"| translate }}
          <mat-icon>close</mat-icon>
        </button>

        <button *ngIf="configuration?.template" mat-raised-button color="primary" (click)="downloadTemplate()">
          Template
          <mat-icon>download</mat-icon>
        </button>

      </div>

      <div>

        <button mat-raised-button color="accent" (click)="import()"> {{ "upload" | translate }}
          <mat-icon>upload_file</mat-icon>
        </button>

      </div>

    </mat-card-actions>
    <mat-card-footer>
      <mat-progress-bar *ngIf="viewModel.loadingData" mode="indeterminate"></mat-progress-bar>
    </mat-card-footer>
  </mat-card-content>
</mat-card>
