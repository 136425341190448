import {CTManagerItem} from "../../../../../../../_core/classes/ct-manager-item";

export class MovementManagerAddItemConfiguration {

  Item: CTManagerItem | null = null;

  private constructor() {
  }

  public static create = () => new MovementManagerAddItemConfiguration();

  setItem(value: CTManagerItem | null) {

    this.Item = value;
    return this;

  }

}
