import {Component, Input} from '@angular/core';
import {
  CtBinaryOperator,
  CtControlConfiguration,
  CtControlService,
  CtControlTypes,
  CTModelDatatableFilter,
  CtModelDatatableOperators,
  CtSelectControlOptions,
  CtSortOrderDescriptorParameter,
  CtThemeTypes
} from "@ctsolution/ct-framework";
import {FormBuilder, FormGroup} from "@angular/forms";
import {QtaControl} from "../../../../../_core/constants";
import {ModuleCreationRequestParameter} from "../../../../../_core/controllers/item.controller";
import {WorkOrderImportParameter} from "../../../../../_core/classes/work-order-import.parameter";
import {DataRequest, MethodEnum} from "@ctsolution/ct-webapi";

@Component({
  selector: 'app-module-create-form',
  templateUrl: './module-create-form.component.html',
  styleUrls: ['./module-create-form.component.scss']
})
export class ModuleCreateFormComponent {

  @Input() parameter: ModuleCreationRequestParameter | WorkOrderImportParameter | null = null;

  qtyControl: CtControlConfiguration = QtaControl("qty")
    .setLabel("quantity");
  locationToControl: CtControlConfiguration =
    this.controlService.getDefaultConfiguration({
      name: 'LocationToOid',
      label: 'locationTo',
      type: CtControlTypes.LOOKUP,
      required: true,
      remoteValueOptionsEndpoint: DataRequest
        .create()
        .setController(['Location'])
        .setAction('Lookup')
        .setMethod(MethodEnum.POST)
        .setQueryParams({hideSpinner: true})
    })
      .setTheme(CtThemeTypes.MATERIAL)
      .configureOptions<CtSelectControlOptions>(options => {

        if (!options) options = CtSelectControlOptions.create();

        options
          .setNullValueEnabled(false)
          .setLookupFilter((value) => {

            const filters: CTModelDatatableFilter[] = [
              CTModelDatatableFilter
                .create()
                .setField('LoadPermitted')
                .setValue(true)
                .setOperatorType(CtBinaryOperator.Equal),
            ];

            if (value) {

              filters
                .push(
                  CTModelDatatableFilter.create().setField('Name').setValue(value).setOperatorType(CtBinaryOperator.Contains))
            }

            if (this.parameter instanceof WorkOrderImportParameter) {

              filters
                .push(
                  CTModelDatatableFilter
                    .create()
                    .setField('WorkOrder.Oid')
                    .setValue(this.parameter.WorkOrderOid)
                    .setOperatorType(CtBinaryOperator.Equal)
                )

            }

            const operators = CtModelDatatableOperators
              .create()
              .setFilters(filters);

            operators
              .Pagination
              ?.setSortOrders(new Array<CtSortOrderDescriptorParameter>(CtSortOrderDescriptorParameter.create('Name')))

            return operators;

          })

      });

  form: FormGroup;

  viewModel = {
    warning: 'ModuleCreateForm.Warning'
  }

  get needsQuantity() {

    return this.parameter instanceof ModuleCreationRequestParameter;

  }

  constructor(
    private controlService: CtControlService,
    private formBuilder: FormBuilder) {

    this.form = this.formBuilder.group({});

    this.qtyControl
      .setFormParent(this.form);

    this.locationToControl
      .setFormParent(this.form);

  }

  ngOnInit() {

    this.setupModel();

  }

  setupModel() {

    if (this.parameter instanceof WorkOrderImportParameter) {

      this.viewModel.warning = "workOrderImport.warning";

      this.qtyControl
        .control
        ?.setValidators(null);

      this.qtyControl
        .control
        ?.updateValueAndValidity();

    }

  }

}
