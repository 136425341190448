import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {WorkOrderEditComponent} from "./work-order-edit.component";
import {WorkOrderDefaultEditComponent} from './work-order-default-edit/work-order-default-edit.component';
import {MatTabsModule} from "@angular/material/tabs";
import {GeneralDataModule} from "./work-order-default-edit/general-data/general-data.module";
import {MovementManagerModule} from "./work-order-default-edit/movement-manager/movement-manager.module";

@NgModule({
  declarations: [
    WorkOrderEditComponent,
    WorkOrderDefaultEditComponent
  ],
  imports: [
    CommonModule,
    MatTabsModule,
    GeneralDataModule,
    MovementManagerModule
  ],
  exports: [
    WorkOrderEditComponent
  ]
})
export class WorkOrderEditModule {
}
