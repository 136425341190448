export class WorkOrderImportParameter {

  File: File | null = null;
  WorkOrderOid: number | null = null;
  LocationOid: number | null = null;
  Finalize: boolean | null = null;

  private constructor() {
  }

  public static create = (): WorkOrderImportParameter => new WorkOrderImportParameter();

  setFile(value: File | null): WorkOrderImportParameter {

    this.File = value;
    return this;

  }

  setWorkOrderOid(value: number | null): WorkOrderImportParameter {

    this.WorkOrderOid = value;
    return this;

  }

  setLocationOid(value: number | null): WorkOrderImportParameter {

    this.LocationOid = value;
    return this;

  }

  setFinalize(value: boolean): WorkOrderImportParameter {

    this.Finalize = value;
    return this;

  }

}
