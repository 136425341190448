import {Injectable} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {CTMWorkOrderViewType} from "../../_core/enum/work-order-type";
import {
  CtBinaryOperator,
  CtControlService,
  CtControlTypes,
  CtModelConfiguration,
  CTModelDatatableFilter,
  CtModelDatatableOperators,
  CtModelRouteData,
  CtModelService, CtSelectControlOptions, CtSelectControlRemoteDefaults, CtSortOrderDescriptorParameter
} from "@ctsolution/ct-framework";
import {WorkOrderRowRouteData} from "../../_core/route-data/work-order-row.route-data";
import {WorkOrderInformationRouteData} from "../../_core/route-data/work-order-information.route-data";
import {CtWebapiGenericResponse, DataRequest, MethodEnum} from "@ctsolution/ct-webapi";
import {CTWorkOrderRow} from "../../_core/classes/ct-work-order.row";
import {DTSWorkOrderRow} from "../../../../../dts/src/app/work-order/dts-work-order.row";
import {CTWorkOrderInformation} from "../../_core/classes/ct-work-order.information";
import {DTSWorkOrderInformation} from "../../../../../dts/src/app/work-order/dts-work-order.information";

const WorkOrderRowConfig = () => CtModelConfiguration.create().setRouteData(WorkOrderRowRouteData());
const WorkOrderInformationConfig = () => CtModelConfiguration.create().setRouteData(WorkOrderInformationRouteData());
const WorkOrderOidFilter = (workOrderOid: number) => CTModelDatatableFilter.create().setField("WorkOrder.Oid").setValue(workOrderOid).setOperatorType(CtBinaryOperator.Equal);

@Injectable()
export class WorkOrderService {

  constructor(private CTModelService: CtModelService<any>, private controlService: CtControlService) {
  }

  getTextControl(value: { name: string, required?: boolean | null; flexSpacePercentage?: number | null }) {

    return this.controlService
      .getDefaultConfiguration<string | null>({
        name: value.name,
        flexSpacePercentage: value.flexSpacePercentage ?? 50,
        type: CtControlTypes.TEXT,
        required: value.required ?? false
      })

  }

  getRouteWorkOrderType(route: ActivatedRoute): Promise<CTMWorkOrderViewType> {

    return new Promise<CTMWorkOrderViewType>((resolve) => {

      route
        .data
        .subscribe({
          next: routeData => resolve((<any>routeData).type ?? CTMWorkOrderViewType.Standard),
          error: err => resolve(CTMWorkOrderViewType.Standard)
        });

    })

  }

  getRoutePathController(type: CTMWorkOrderViewType): string[] {

    const path: string[] = ['work-order'];

    switch (type) {

      case CTMWorkOrderViewType.Quotation:
        path.push('quotations');
        break;

      case CTMWorkOrderViewType.Standard:
      default:
        break;

    }

    return path;

  }

  getInfos(value: { controller: string, oid?: number }) {

    const configuration = CtModelConfiguration
      .create()
      .setRouteData(CtModelRouteData.create().setController(value.controller))

    if (value.oid) {

      configuration.RouteData?.setId(value.oid);

    }

    return this.CTModelService.getInfos(configuration);

  }

  getList(value: { controller: string, filters?: CTModelDatatableFilter[] }) {

    const configuration = CtModelConfiguration
      .create()
      .setRouteData(CtModelRouteData.create().setController(value.controller));

    const body: CtModelDatatableOperators = CtModelDatatableOperators
      .create()
      .setFilters(value.filters ?? []);

    return this.CTModelService.getList(configuration, body);

  }

  getLookupControlDefaultConfiguration(value: {
    name: string;
    controller: string;
    key?: string | null;
    type?: CtControlTypes | null;
    required?: boolean | null;
  }) {

    const {key, type, required} = {
      key: value.key ?? 'Name',
      type: value.type ?? CtControlTypes.LOOKUP,
      required: value.required ?? true
    };

    const configuration = this.controlService
      .getDefaultConfiguration<string | null>({
        name: value.name,
        type,
        flexSpacePercentage: 50,
        required: required,
        remoteValueOptionsEndpoint: DataRequest
          .create()
          .setController([value.controller])
          .setAction('Lookup')
          .setMethod(MethodEnum.POST)
          .setQueryParams({hideSpinner: true})
      })
      .configureOptions<CtSelectControlOptions>(options => {

        if (!options) options = CtSelectControlOptions.create();

        options
          ?.setNullValueEnabled(false)
          ?.setRemoteDefaults(CtSelectControlRemoteDefaults.create().setSearchKey(key).setSortOrder(CtSortOrderDescriptorParameter.create(key)))

      });

    return configuration;

  }

  getDateControlDefault(value: { name: string; flexSpacePercentage?: number | null; required?: boolean | null; }) {

    const configuration = this.controlService.getDefaultConfiguration<Date | null>({
      name: value.name,
      type: CtControlTypes.DATE,
      required: value.required ?? true,
      flexSpacePercentage: value.flexSpacePercentage ?? 33.33
    })

    return configuration;

  }

  private putData<T>(config: any, value: any) {

    if (value.Oid) config.RouteData?.setId(value.Oid);

    return this.CTModelService.putInfos(config, value)?.toPromise();

  }

  putRow(value: CTWorkOrderRow<DTSWorkOrderRow>) {
    const config = WorkOrderRowConfig();
    return this.putData(config, value);
  }

  putInformation(value: CTWorkOrderInformation<DTSWorkOrderInformation>) {
    const config = WorkOrderInformationConfig();
    return this.putData(config, value);
  }

  private getWorkOrderData<T>(config: any, workOrderOid: number): Promise<any | null> {

    return new Promise<any | null>(resolve => {
      this.getList({
        controller: config.RouteData?.controller!,
        filters: [WorkOrderOidFilter(workOrderOid)]
      }).subscribe((response: CtWebapiGenericResponse<any>) => resolve((response.Result.DataSource ?? [])[0] ?? null));
    });

  }

  getInformations(workOrderOid: number) {
    const config = WorkOrderInformationConfig();
    return this.getWorkOrderData(config, workOrderOid);
  }

  getRows(workOrderOid: number) {
    const config = WorkOrderRowConfig();
    return this.getWorkOrderData(config, workOrderOid);
  }

}
