<div class="work-order-edit-container p-10">

  <mat-tab-group>

    <mat-tab label="Dati generali">

      <ctm-general-data
        #generalDataComponent
        *ngIf="generalDataConfiguration"
        [configuration]="generalDataConfiguration"
        (onSubmit)="submit()"></ctm-general-data>

    </mat-tab>

    <mat-tab
      label="Gestione movimentazioni"
      *ngIf="viewModel.workOrderRowHandlingEnabled">

      <ctm-movement-manager
        #movementManagerComponent
        (onSubmit)="submit()"></ctm-movement-manager>

    </mat-tab>

  </mat-tab-group>

</div>


