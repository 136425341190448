export class LangDownloadFileConfiguration {

  icon: string | null = null;
  color: string | null = 'accent';
  buttonType: 'mat-mini-fab' | 'mat-icon-button' | null = null;

  private constructor() {
  }

  public static create = () => new LangDownloadFileConfiguration();

  setIcon(value: string | null) {

    this.icon = value;
    return this;

  }

  setColor(value: string | null) {

    this.color = value;
    return this;

  }

  setButtonType(value: 'mat-mini-fab' | 'mat-icon-button' | null) {

    this.buttonType = value;
    return this;

  }

}
