import {CTBase} from "@ctsolution/ct-base";
import {CTWorkOrderInformation} from "./ct-work-order.information";
import {CTWorkOrderRow} from "./ct-work-order.row";

export class CTWorkOrder<I, R> extends CTBase<CTWorkOrder<I, R>> {

  Oid?: number;
  Code: string | null = null;
  ExtraString: string | null = null;
  Name: string | null = null;
  ExternalCode: string | null = null;
  StartAt: Date | null = null;
  ExpiredAt: Date | null = null;
  Company: { Oid: number } | any | null = null;
  State: { Oid: number } | any | null = null;
  Contact: { Oid: number } | any | null = null;
  Address: string | null = null;
  Description: string | null = null;
  Informations: Array<CTWorkOrderInformation<I>> = [];
  Rows: Array<CTWorkOrderRow<R>> = [];
  ExtraDataJSON: any | null = null;
  Quotation: boolean = false;
  QuotationAcceptAt: Date | null = null;

  private constructor();
  private constructor(model?: CTWorkOrder<I, R>)
  private constructor(model?: CTWorkOrder<I, R>) {

    super();

    if (model) this.getClass(model);

  }

  public static create = <I, R>(model?: CTWorkOrder<I, R>) => new CTWorkOrder(model);

  setOid(value?: number) {

    this.Oid = value;
    return this;

  }

  setCode(value: string | null) {

    this.Code = value;
    return this;

  }

  setExtraString(value: string | null) {

    this.ExtraString = value;
    return this;

  }

  setExternalCode(value: string | null) {

    this.ExternalCode = value;
    return this;

  }

  setName(value: string | null) {

    this.Name = value;
    return this;

  }

  setAddress(value: string | null) {

    this.Address = value;
    return this;

  }

  setDescription(value: string | null) {

    this.Description = value;
    return this;

  }

  setStartAt(value: Date | null) {

    this.StartAt = value;
    return this;

  }

  setExpiredAt(value: Date | null) {

    this.ExpiredAt = value;
    return this;

  }

  setQuotationAcceptAt(value: Date | null) {

    this.QuotationAcceptAt = value;
    return this;

  }

  setQuotation(value: boolean) {

    this.Quotation = value;
    return this;

  }

  setCompanyOid(value: number) {

    this.Company = {Oid: value};
    return this;

  }

  setContactOid(value: number) {

    this.Contact = {Oid: value};
    return this;

  }

  setStateOid(value: number) {

    this.State = {Oid: value};
    return this;

  }

  setExtraDataJSON(value: any | null) {

    this.ExtraDataJSON = value;
    return this;

  }

}
