import {Component} from '@angular/core';
import {
  CtButtonConfiguration,
  CtControlConfiguration,
  CtControlValidator,
  CTFieldInfos,
  CtFormContainerHelper,
  CtSelectControlOptions, CtSelectControlRemoteDefaults,
  CtSelectControlValue,
  CtSortOrderDescriptorParameter,
  CtThemeTypes,
  MAT_RAISED_ACCENT,
  MAT_RAISED_WARN
} from "@ctsolution/ct-framework";
import {MatLegacyDialogRef} from "@angular/material/legacy-dialog";
import {FormBuilder, FormGroup} from "@angular/forms";
import {environment} from "../../../../../environments/environment";
import {CtWebapiGenericResponse} from "@ctsolution/ct-webapi";
import {CompanyContactRelationService} from "../company-contact-relation.service";

const CompanyContactUserInfoControl: CTFieldInfos = CTFieldInfos.create({
  IsDTO: true,
  Field: "Contact",
  Type: "ContactDTO",
  IsNullable: false,
  ControlType: "Lookup",
  LookupInfos: {
    RemoteEndpoint: `${environment.dev.REST_API_SERVER}/Contact/Lookup`,
    IncrementalSearch: true
  },
} as CTFieldInfos);

@Component({
  selector: 'app-company-contact-relation-add',
  templateUrl: './company-contact-relation-add.component.html',
  styleUrls: ['./company-contact-relation-add.component.scss']
})
export class CompanyContactRelationAddComponent {

  form: FormGroup;

  cancelButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("close")
    .setAction(() => this.onCancel())
    .setMatherialOptions(MAT_RAISED_WARN);

  addButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_DATATABLE.create")
    .setAction(() => this.onAdd())
    .setMatherialOptions(MAT_RAISED_ACCENT);

  control: CtControlConfiguration = CtControlConfiguration
    .create(
      CtFormContainerHelper
        .create()
        .getCTFormControlByCTFieldInfo(CompanyContactUserInfoControl))
    .setTheme(CtThemeTypes.MATERIAL)
    .setValidators([CtControlValidator.create({name: 'required'} as CtControlValidator)])
    .configureOptions<CtSelectControlOptions>(options => {

      if (!options) options = CtSelectControlOptions.create();

      options
        .setRemoteDefaults(
          CtSelectControlRemoteDefaults
            .create()
            .setSearchKey('Name')
            .setSortOrder(CtSortOrderDescriptorParameter.create('Name'))
        )
        .setEnableAddOptionButton(true)
        .setOnAddOptionButtonClick(() => new Promise<CtWebapiGenericResponse<number> | null>((resolve) => {

          this.companyContactRelationService
            .createContact()
            .then((result) => {

              if (result.Oid) {

                setTimeout(() => {

                  this.control
                    .setValue(
                      CtSelectControlValue
                        .create()
                        .setLabel(`${result.Name} ${result.Surname}`)
                        .setValue(result.Oid))

                }, 100)

              }

              resolve(result)

            })

        }))
        .setNullValueEnabled(false)


    })


  constructor(private dialogRef: MatLegacyDialogRef<any>, private formBuilder: FormBuilder, private companyContactRelationService: CompanyContactRelationService) {

    this.form = this.formBuilder.group({})

  }

  ngAfterViewInit() {

    this.setup()

  }

  private async setup() {

    this.control
      .setFormParent(this.form);

  }

  onCancel() {

    this.dialogRef
      .close(null);

  }

  onAdd() {

    if (this.form.valid) {

      this.dialogRef
        .close(this.form.value.Contact.value);

    }

  }

}
