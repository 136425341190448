import {ChangeDetectorRef, Component, Inject} from '@angular/core';
import {
  CtButtonConfiguration,
  CtControlTypes,
  CtFormConfiguration,
  CtThemeTypes,
  MAT_RAISED_ACCENT,
  MAT_RAISED_WARN
} from "@ctsolution/ct-framework";
import {FormBuilder} from "@angular/forms";
import {MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef} from "@angular/material/legacy-dialog";
import {WorkOrderService} from "../../../../../work-order.service";
import {MovementManagerAddItemConfiguration} from "./movement-manager-add-item.configuration";
import {CTManagerItem} from "../../../../../../../_core/classes/ct-manager-item";

@Component({
  selector: 'app-movement-manager-add-item',
  templateUrl: './movement-manager-add-item.component.html',
  styleUrls: ['./movement-manager-add-item.component.scss']
})
export class MovementManagerAddItemComponent {

  cancelButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("close")
    .setAction(() => this.onCancel())
    .setMatherialOptions(MAT_RAISED_ACCENT);

  addButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_FORM.save")
    .setAction(() => this.onAdd())
    .setMatherialOptions(MAT_RAISED_WARN);

  formConfiguration: CtFormConfiguration | null = null;

  private controls = [
    this.workOrderService.getTextControl({name: 'Code', required: true, flexSpacePercentage: 100}),
    this.workOrderService.getTextControl({
      name: 'Description',
      required: true
    }).setType(CtControlTypes.TEXTAREA).setFlexSpacePercentage(100),
    this.workOrderService.getTextControl({
      name: 'Amount',
      required: true,
      flexSpacePercentage: 33.33
    }).setType(CtControlTypes.NUMBER),
    this.workOrderService.getTextControl({
      name: 'Discount',
      required: false,
      flexSpacePercentage: 33.33
    }).setType(CtControlTypes.NUMBER).setValue(0),
    this.workOrderService.getTextControl({
      name: 'IVA',
      required: false,
      flexSpacePercentage: 33.33
    }).setType(CtControlTypes.NUMBER)
      .setValue(22)
  ];

  constructor(
    private cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private workOrderService: WorkOrderService,
    @Inject(MAT_LEGACY_DIALOG_DATA) private config: MovementManagerAddItemConfiguration,
    private dialogRef: MatLegacyDialogRef<MovementManagerAddItemComponent>) {

    this.formConfiguration = CtFormConfiguration
      .create(this.formBuilder.group({}))
      .setTheme(CtThemeTypes.MATERIAL)
      .setControls(this.controls);

    if (this.config) {

      this.setup(this.config.Item)

    }

  }

  ngAfterViewInit() {

    this.cdr.detectChanges();

  }

  setup(item: CTManagerItem | null) {

    this.formConfiguration
      ?.form
      ?.patchValue(item ?? {})

    if (item?.Name) {

      this.formConfiguration?.form?.get('Description')?.setValue(item.Name)

    }

  }

  onAdd() {

    if (this.formConfiguration?.form?.valid) {

      this.dialogRef
        .close(this.formConfiguration.form.value)

    }

  }

  onCancel() {

    this.dialogRef
      .close();

  }


}
