import {Component, OnInit} from '@angular/core';
import {
  CtBinaryOperator,
  CtControlConfiguration,
  CTFieldInfos,
  CtFormContainerHelper,
  CTModelDatatableFilter,
  CtModelDatatableOperators,
  CtSelectControlOptions,
  CtSortOrderDescriptorParameter,
  CtThemeTypes
} from "@ctsolution/ct-framework";
import {environment} from "../../../../../../environments/environment";

const UserWorkingHoursWorkOrderControl: CTFieldInfos = CTFieldInfos.create({
  IsDTO: true,
  Field: "WorkOrder",
  Type: "WorkOrderDTO",
  IsNullable: false,
  ControlType: "Lookup",
  LookupInfos: {
    RemoteEndpoint: `${environment.dev.REST_API_SERVER}/WorkOrder/Lookup`,
    CodeField: "Code",
    DescriptionField: "Description",
    IncrementalSearch: false
  },
} as CTFieldInfos);

export enum WorkOrderMacroType {

  IN_ESECUZIONE = 2,
  ATTIVO = 3

}

@Component({
  selector: 'app-user-working-hours-work-order-control',
  template: `
    <ct-control [configuration]="configuration"></ct-control>`
})
export class UserWorkingHoursWorkOrderControlComponent implements OnInit {

  configuration: CtControlConfiguration | null = null;

  ngOnInit() {

    this.setup();

  }

  setup() {

    const configuration = CtControlConfiguration
      .create(CtFormContainerHelper
        .create()
        .getCTFormControlByCTFieldInfo(UserWorkingHoursWorkOrderControl))
      .setTheme(CtThemeTypes.MATERIAL)
      .configureOptions<CtSelectControlOptions>(options => {

        if (!options) options = CtSelectControlOptions.create();

        options
          .setLookupFilter(() => {

            const filters: CTModelDatatableFilter[] = [
              CTModelDatatableFilter
                .create()
                .setValue([WorkOrderMacroType.IN_ESECUZIONE, WorkOrderMacroType.ATTIVO])
                .setField('State.Type==?')
                .setOperatorType(CtBinaryOperator.BitwiseOr)
            ];

            const operators = CtModelDatatableOperators
              .create()
              .setFilters(filters);

            operators
              .Pagination
              ?.setSortOrders(new Array<CtSortOrderDescriptorParameter>(CtSortOrderDescriptorParameter.create('Name')))

            return operators;

          })
          .setNullValueEnabled(true)
          .notifyValuesUpdated()

      });

    this.configuration = configuration;

  }

}
